import React, { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Button, DropdownItem, PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { signOut, useSession } from 'next-auth/react';
import { twMerge } from 'tailwind-merge';
import { Dropdown } from '@components/atoms/Dropdown/Dropdown';
import { Logo } from '@components/atoms/Logo/Logo';
import { NavItem } from '@components/atoms/NavItem/NavItem';
import { SearchButton } from '@components/atoms/SearchButton/SearchButton';
import { IconUser } from '@src/assets/icon-user';
import AppContext from '@lib/AppContext';
import { HeaderNav } from '../../molecules/HeaderNav/HeaderNav';

type UserDropdownItemType = {
  id: string;
  label: string;
  link?: string;
  action?: () => void;
};

const getUserDropdownItems = (callbackUrl): UserDropdownItemType[] => [
  { id: 'watchlist', label: 'Watchlist', link: '/watchlist' },
  { id: 'settings', label: 'Einstellungen', link: '/settings' },
  {
    id: 'auth/logout',
    label: 'Abmelden',
    action: () => {
      signOut({ callbackUrl });
    },
  },
];

const UserDropdownItem = ({ item, callbackUrl }: { item: DropdownItem; callbackUrl: string }) => {
  const dropdownItem = getUserDropdownItems(callbackUrl).find((e) => e.id === item.id);
  return (
    <Button
      hasAction={!!dropdownItem.action}
      onAction={dropdownItem.action}
      link={dropdownItem.link}
      classProps={{
        root: 'hover:underline decoration-2 hover:underline-offset-4 hover:decoration-primary text-[15px] font-bold',
      }}
    >
      {dropdownItem.label}
    </Button>
  );
};

export interface HeaderProps extends PropsWithClassProps {
  filled?: boolean;
  hideMobileNav?: boolean;
  disableTopSpacing?: boolean;
}

export const Header = ({ filled, hideMobileNav, disableTopSpacing }: HeaderProps) => {
  const { status } = useSession();
  const [currentPath, setCurrentPath] = useState<string>(null);
  const context = useContext(AppContext);
  const mobileSubItems = context.header.navigation?.items.slice(2);
  const router = useRouter();

  useEffect(() => {
    setCurrentPath(window.location.pathname);
  }, []);

  return (
    <>
      <header
        className={twMerge(
          'pointer-events-none fixed z-60 grid h-26 w-full content-center items-center gap-x-1.5 px-4 text-white sm:gap-0 lg:h-24 lg:px-12 xl:px-16',
          filled ? 'bg-gray-900' : 'bg-gray-900 lg:bg-gray-900/0',
          hideMobileNav ? 'h-[4.65rem]' : 'h-26',
        )}
      >
        <div className="grid grid-cols-[1fr,auto,1fr] items-center gap-2">
          <Logo responsive link />
          <HeaderNav classProps={{ root: 'self-center' }} />
          <div className={'pointer-events-auto z-10 flex items-center justify-end gap-1.5 md:gap-4'}>
            <div className="block">
              <div className="flex h-8 cursor-pointer items-center rounded-full border-0.5 border-gray-650/65 bg-gray-650/65 backdrop-blur-sm md:h-12 lg:bg-gray-800/70">
                <Button
                  classProps={{
                    root: 'hidden px-2 md:px-4 h-full tracking-widest text-[9px] font-bold lg:text-base md:text-sm uppercase font-proxima-nova lg:flex items-center justify-center rounded-full border-0.5 border-transparent transition-colors duration-200 hover:border-primary hover:text-primary',
                  }}
                  link="https://www.tv-media.at/abo"
                >
                  Abo
                </Button>
                {process.env.NEXT_PUBLIC_SHOW_LOGIN === 'true' && (
                  <>
                    {status === 'authenticated' ? (
                      <Dropdown
                        items={getUserDropdownItems(router)}
                        selectedItem={{ id: '2', label: 'Item' }}
                        setSelectedItem={() => null}
                        openOnHover
                        classProps={{
                          root: 'h-full',
                          dropdownWrapper: 'h-full',
                          dropdownButtonWrapper: 'h-full',
                          button: 'h-full',
                          itemWrapper: 'right-0',
                        }}
                        CustomButton={() => (
                          <Button
                            classProps={{
                              root: 'px-2 md:px-4 h-full tracking-widest font-bold lg:text-base md:text-sm flex items-center justify-center rounded-full border-0.5 border-transparent transition-colors duration-200 hover:border-primary hover:text-primary',
                            }}
                            hasAction
                          >
                            <IconUser
                              classProps={{
                                root: twMerge('size-3.5 md:size-[18px] text-primary'),
                              }}
                            />
                          </Button>
                        )}
                        CustomListItem={(item) => (
                          <UserDropdownItem item={item.item as DropdownItem} callbackUrl={currentPath} />
                        )}
                        placeholder=""
                      />
                    ) : (
                      <Button
                        classProps={{
                          root: 'px-2 md:px-4 h-full tracking-widest font-bold lg:text-base md:text-sm flex items-center justify-center rounded-full border-0.5 border-transparent transition-colors duration-200 hover:border-primary hover:text-primary',
                        }}
                        link={`/auth/login?callbackUrl=${encodeURIComponent(currentPath)}`}
                      >
                        <IconUser classProps={{ root: 'size-3.5 md:size-[18px]' }} color="currentColor" />
                      </Button>
                    )}
                  </>
                )}
              </div>
            </div>
            <SearchButton />
          </div>
        </div>
        {!hideMobileNav && (
          <nav className="pointer-events-auto flex items-center justify-center gap-4 text-[11px] sm:gap-3 lg:hidden">
            {mobileSubItems?.map((item, index) => <NavItem item={item} key={index} type="sub" />)}
            <NavItem
              item={{ title: 'Abo', url: '/abo', id: 'abo' }}
              key="abo"
              type="sub"
              classProps={{ root: 'pl-3 border-l border-gray-650/75 h-4 leading-none' }}
            />
          </nav>
        )}
      </header>
      {!disableTopSpacing && <div className={twMerge('lg:hidden', hideMobileNav ? 'h-[4.65rem]' : 'h-26')} />}
    </>
  );
};
